import { useDispatch } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import {
  PreferencesActions,
  PreferencesSelector,
} from "redux/slices/preference";

const GuestGuard = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { token } = PreferencesSelector();

  if (pathname === "/redirect") {
    return <>{children}</>;
  }

  if (pathname === "/questionnaire" && token == null) {
    return <Navigate to="/" />;
  }

  if (
    pathname === "/" &&
    localStorage.getItem("token") &&
    localStorage.getItem("prev-questionnaire")
  ) {
    dispatch(PreferencesActions.setToken(localStorage.getItem("token")));
    return <Navigate to="/questionnaire" />;
  }

  return <>{children}</>;
};

export default GuestGuard;
