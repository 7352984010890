import { orderBy } from "lodash";
import { authRequest } from "services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { PLATFORM_ID } from "utils/config";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  questionnaires: [],
  questions: null,
  loadingQuestion: true,
  currentStep: null,
  result: null,
  loadingResult: true,
  selectedQuestionnaire: null,
  openAgreement: false,
  onRating: false,
  loadingRating: true,
  token: null,
  term: null,
};

const slice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    setQuestions(state, action) {
      state.questions = action.payload;
    },
    setLoadingQuestion(state, action) {
      state.loadingQuestion = action.payload;
    },
    setQuestionnaires(state, action) {
      state.questionnaires = action.payload;
    },
    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },
    setResult(state, action) {
      state.result = action.payload;
    },
    setLoadingResult(state, action) {
      state.loadingResult = action.payload;
    },
    setLoadingRating(state, action) {
      state.loadingRating = action.payload;
    },
    setSelectedQuestionnaire(state, action) {
      state.selectedQuestionnaire = action.payload;
    },
    setOpenAgreement(state, action) {
      state.openAgreement = action.payload;
    },
    setOpenRating(state, action) {
      state.onRating = action.payload;
    },
    setToken(state, action) {
      state.token = action.payload;
    },
    setTerm(state, action) {
      state.term = action.payload;
    },
    clear(state) {
      state.questionnaires = [];
      state.questions = null;
      state.loadingQuestion = true;
      state.currentStep = null;
      state.result = null;
      state.loadingResult = true;
      state.selectedQuestionnaire = null;
      state.openAgreement = false;
      state.onRating = false;
      state.loadingRating = true;
      state.token = null;
      state.term = null;
    },
  },
});

export const initPreferences = async ({ dispatch }) => {
  try {
    const platform_id = PLATFORM_ID;
    let res = await authRequest.get("/api/v01/platforms/" + platform_id);
    const questionnaires = orderBy(res?.payload, ["ordering"], ["asc"]);

    dispatch(PreferencesActions?.setQuestionnaires(questionnaires));

    res = await authRequest.get(`/api/v01/${platform_id}/platforms`);
    const term = {
      en: res?.payload?.preferences.find((x: any) => x.key === "term")
        ?.value_en,
      kh: res?.payload?.preferences.find((x: any) => x.key === "term")
        ?.value_kh,
    };

    dispatch(PreferencesActions?.setTerm(term));
  } catch (error) {
    toast.error(error.message);
  }
};

export const initQuestionByStepIndex = async ({
  dispatch,
  stepIndex,
  displayedQuestionnaires,
}) => {
  dispatch(PreferencesActions?.setLoadingQuestion(true));
  dispatch(PreferencesActions?.setCurrentStep(stepIndex));
  dispatch(
    PreferencesActions?.setSelectedQuestionnaire(
      displayedQuestionnaires[stepIndex]
    )
  );
  dispatch(
    PreferencesActions?.setQuestions(
      displayedQuestionnaires[stepIndex]?.questions
    )
  );

  dispatch(PreferencesActions?.setLoadingQuestion(false));
};

export const PreferencesActions = slice.actions;
export const PreferencesSelector = (): typeof initialState =>
  useSelector((state: any) => state.preferences);

export default slice.reducer;
