import LoadingScreen from "components/LoadingScreen";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { initPreferences, PreferencesActions } from "redux/slices/preference";
import { authRequest } from "services";
import { PLATFORM_ID } from "utils/config";

const GuestLayout = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const checkCache = async () => {
    try {
      const res = await authRequest.get(`/api/v01/${PLATFORM_ID}/platforms`);
      const enable_cache = res.payload?.enable_cache;
      if (enable_cache === false && !pathname.includes("/result")) {
        localStorage.clear();
        dispatch(PreferencesActions.clear());
        initPreferences({ dispatch });
        navigate("/");
        return;
      }
      initPreferences({ dispatch });
    } catch (error) {
      localStorage.clear();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (pathname !== "/redirect") {
      checkCache();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  return (
    <>
      <Outlet />
    </>
  );
};

export default GuestLayout;
