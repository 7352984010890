import { combineReducers } from "redux";
import storage from "redux-persist/lib/storage";
import settings from "redux/slices/setting";
import preferences from "redux/slices/preference";

export const rootPersistConfig = {
  key: "root",
  storage,
  keyPrefix: "redux-",
  whitelist: ["settings", "preferences"],
};

export const rootReducer = combineReducers({
  settings,
  preferences,
});

export type RootState = ReturnType<typeof rootReducer>;
