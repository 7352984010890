import LoadingLayout from "components/LoadingLayout";
import GuestGuard from "guards/guest";
import GuestLayout from "layouts/guest";
import { lazy, Suspense } from "react";
import { useRoutes } from "react-router-dom";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingLayout loading={true} children={null} />}>
      <Component {...props} />
    </Suspense>
  );
};

const LazyLoad = (path: string) =>
  Loadable(lazy(() => import(`pages/${path}`)))({});

export default function AppRoutes() {
  return useRoutes([
    {
      path: "",
      element: (
        <GuestGuard>
          <GuestLayout />
        </GuestGuard>
      ),
      children: [
        { path: "", element: LazyLoad("index") },
        {
          path: "/questionnaire",
          element: LazyLoad("start/index"),
        },
        {
          path: "result",
          element: LazyLoad("Result/index"),
        },
        {
          path: "/redirect",
          element: LazyLoad("redirect/index"),
        },
      ],
    },
    {
      path: "*",
      element: LazyLoad("404"),
    },
  ]);
}
